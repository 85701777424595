<template>
  <div>
    <Header />
    <pageTitle txt="発送指示書 全店" :backBtn="backBtn" />
    <div id="meta">
      <div class="meta-date">
        {{ this.$route.query.date | changeDateObject | dateFormatShort }}
      </div>
      <div class="meta-txt">出荷分</div>
      <div class="meta-label">
        <span v-if="this.isDecision">確定</span>
        <span v-else>{{ today | dateFormatShort }}時点暫定</span>
      </div>
      <div class="meta-box">
        箱数
        <span class="meta-box__num">{{ boxCount | comma }}</span>
        個
      </div>
      <div
        @click="(print = !print), (overlay = !overlay), setPrint()"
        class="print-btn"
      >
        <btnS class="print" btnTxt="印刷" color="primary" />
      </div>
    </div>
    <div id="wrapper">
      <ul class="list">
        <li class="list-item" v-for="product in items" :key="product.item_id">
          <div class="list-item__data data-name">{{ product.name }}</div>
          <div class="list-item__data data-blk">
            <div class="data-quantity">
              <span class="num">
                {{ product.total_num | comma }}
              </span>
              <span class="unit">{{ product.unit }}</span>
            </div>
            <div class="data-vacuum">
              <div class="vacuum-txt">内 真空</div>
              <span class="num">
                {{ product.bacuum_num | comma }}
              </span>
              <span class="unit">{{ product.unit }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div id="overlay" :class="{ visible: overlay }">
      <div class="print-paper portrait" v-if="print">
        <div class="print-inner">
          <div class="print-meta">
            <div class="meta-date">
              {{ this.$route.query.date | changeDateObject | dateFormatShort }}
            </div>
            <div class="meta-txt">出荷分</div>
            <div class="meta-label">
              <span v-if="isDecision">確定</span>
              <span v-else>
                {{ today | changeDateObject | dateFormatShort }}時点暫定
              </span>
            </div>
            <div class="meta-box">
              箱数
              <span class="meta-box__num">{{ boxCount | comma }}</span>
              個
            </div>
          </div>
          <ul class="list">
            <li class="list-item" v-for="product in items" :key="product.id">
              <div class="list-item__data data-name">{{ product.name }}</div>
              <div class="list-item__data data-blk">
                <div class="data-quantity">
                  <span class="num">
                    {{ product.total_num | comma }}
                  </span>
                  <span class="unit">{{ product.unit }}</span>
                </div>
                <div class="data-vacuum">
                  <div class="vacuum-txt">内 真空</div>
                  <span class="num">
                    {{ product.bacuum_num | comma }}
                  </span>
                  <span class="unit">{{ product.unit }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id="fixedMenu" v-if="print">
        <div
          v-on:click="(print = !print), (overlay = !overlay), removeSetPrint()"
        >
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div onclick="print()">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.meta-date {
  @extend .f_pageTtl;
  margin-right: 10px;
  &.sunday,
  &.holiday {
    color: variables.$sunday;
  }
  &.saturday {
    color: variables.$saturday;
  }
}

.meta-txt {
  @extend .f_pageTtl;
  margin-right: 20px;
}

.meta-label {
  font-size: 1.5rem;
  letter-spacing: 0.06em;
  box-sizing: border-box;
  padding: 0 15px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px variables.$txt-default;
}

.meta-box {
  @extend .f_pageTtl;
  margin-left: 70px;
}

.meta-box__num {
  font-size: 2rem;
  display: inline-block;
  margin: 0 4px 0 7px;
}

.print-btn {
  margin-left: auto;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 160px;
}

.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 1280px;
  margin: 0 auto 20px;
}

.list-item {
  width: 200px;
  height: 120px;
  position: relative;
  box-sizing: border-box;
  border: solid 1px variables.$bg-gray-2;
  margin: 0 15px 20px 0;
  &:nth-of-type(6n) {
    margin-right: 0;
  }
}

.data-name {
  @extend .f_body;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-blk {
  height: 88px;
  background: variables.$bg-gray-1;
  display: flex;
  padding: 10px 15px 20px;
  box-sizing: border-box;
  .num {
    font-size: 2.4rem;
    letter-spacing: 0.05em;
    margin-right: 2px;
    white-space: nowrap;
  }
  .unit {
    @extend .f_body;
    position: relative;
    top: -4px;
  }
}

.data-quantity {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
}

.data-vacuum {
  color: variables.$scrollbar-back;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
}

.vacuum-txt {
  @extend .f_body;
  width: 100%;
  text-align: right;
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  box-sizing: border-box;
  padding: 30px 0 120px;
  overflow: scroll;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.print-inner {
  .list {
    width: 900px;
  }
  .list-item {
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    &:nth-of-type(6n) {
      margin-right: 15px;
    }
  }
}

.print-meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.print-time {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 30px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}

#add,
#edit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-form {
  display: flex;
  flex-wrap: wrap;
}

.dialog-form__item {
  @extend .f_caption;
  margin-top: 35px;
  &.name {
    width: 220px;
  }
  &.tax {
    width: 160px;
  }
  &.price {
    width: 120px;
  }
  &.note {
    width: 400px;
  }
  &.name,
  &.tax {
    margin-right: 20px;
  }
  label {
    margin-bottom: 5px;
    display: block;
  }
  input,
  select {
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
    width: 100%;
    height: 28px;
    padding: 0 12px;
  }
  .select {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$txt-default transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  .buttonS {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "instructionsDetailAll",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      print: false,
      today: "",
      overlay: false,
      weekday: "",
      isDecision: false,
      items: [],
      boxCount: 0,
      rowNum: 1, //店舗数 / カラム数
      dataPerRow: 8, //1ページあたりのカラム数
      dataPerPage: 20, //1ページあたりのデータ数
      pagePerItems: 1, //商品1周分で何枚必要か
      printNum: 1, //印刷枚数 = 商品数 / dataPerPage * rowNum
      printDate: ""
    };
  },
  watch: {
    todaysLock: function(newV, oldV) {
      console.log("watch : " + newV, oldV);
      if (newV) {
        this.setDecision(newV);
      }
    }
  },
  methods: {
    setDecision: function(e) {
      this.isDecision = e;
    },
    setPrint() {
      let html = document.querySelector("html");
      html.classList.add("print-portrait");
      document.body.classList.add("print-portrait");
    },
    removeSetPrint() {
      let html = document.querySelector("html");
      html.classList.remove("print-portrait");
      document.body.classList.remove("print-portrait");
    }
  },
  async created() {
    this.today = new Date();
    const selectDate = new Date(this.$route.query.date);
    //this.isDecision = selectDate.getTime() <= this.today.getTime();
    // 1日前&&ロック || 2日以上前は確定表示
    //let d = (this.ymd(selectDate) - this.ymd(this.today)) / 86400000;
    let d =
      (this.calcableDate(selectDate) - this.calcableDate(this.today)) /
      86400000;
    console.log({ d });
    console.log("todaysLock : " + this.todaysLock);
    console.log(this.calcableDate(selectDate));
    console.log(this.calcableDate(this.today));

    if (d == 1 && this.todaysLock) {
      this.isDecision = true;
      console.log("d == 1 && this.todaysLock");
    } else if (d < 2 && d >= 1) {
      this.isDecision = false;
      console.log("d == 1");
    }
    //1日後より前 d = 0以下
    if (d <= 0) {
      this.isDecision = true;
      console.log("d <= 0");
    }
    //2日以上先 d = 2以上
    if (d >= 2) {
      this.isDecision = false;
      console.log("d >= 2");
    }

    const param = {
      date: this.$route.query.date
    };
    const api = new ApiClient();
    const result = await api.post("/iseya/instruction/item", param);
    this.items = result.list;
    this.boxCount = result.box;
    this.rowNum = 6;
    this.printNum = (this.items.length / this.dataPerPage) * this.rowNum;
    this.pagePerItems = this.items.length / this.dataPerPage;
  },
  computed: {
    todaysLock: {
      get() {
        return this.$store.state.todaysLock;
      },
      set(newV) {
        this.todaysLock = newV;
      }
    }
  }
};
</script>
