<template>
  <button :type="type" class="buttonL" :class="[color, icon]" @click="click">
    {{ btnTxt }}
  </button>
</template>

<script>
export default {
  name: "btnL",
  props: {
    btnTxt: String,
    color: String,
    icon: String,
    type: {
      type: String,
      default: "button"
    }
  },
  methods: {
    click(e) {
      this.$emit("click", e);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.buttonL {
  width: 315px;
  height: 60px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
  @extend .f_btnTxt;
  position: relative;
}
.primary {
  background: variables.$primary;
  color: #fff;
}
.neutral {
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  border: 1px solid #cbcbcb;
  color: variables.$txt-default;
}
.negative {
  background: variables.$negative;
  color: #fff;
}
.positive1 {
  background: #063d7c;
  color: #fff;
}
.positive2 {
  background: #5c86b5;
  color: #fff;
}
.external {
  background: {
    image: url(../../assets/icon/external.svg);
    size: 12px auto;
    repeat: no-repeat;
    position: top 25px left 50px;
  }
}
</style>
