<script>
export default {
  filters: {
    comma(n) {
      const number = parseFloat(n, 10);
      if (number) {
        return number.toLocaleString();
      }
      return "0";
    },
    // 日付をフォーマット
    dateFormat(date) {
      const weekName = ["日", "月", "火", "水", "木", "金", "土"];
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();

      let str = y + "年" + m + "月" + d + "日 ";
      return str + weekName[date.getDay()] + "曜日";
    },
    dateFormatShort(date) {
      if (!date) {
        return "";
      }
      const weekName = ["日", "月", "火", "水", "木", "金", "土"];
      const m = date.getMonth() + 1;
      const d = date.getDate();

      let str = m + "月" + d + "日 (";
      return str + weekName[date.getDay()] + ")";
    },
    dateFormatYmdJp(date) {
      if (!date) {
        return "";
      }
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();

      let str = y + "年" + m + "月" + d + "日";
      return str;
    },
    // 日付をフォーマット
    dateFormatYM(date) {
      const y = date.getFullYear();
      const m = date.getMonth() + 1;

      let str = y + "年" + m + "月";
      return str;
    },
    // 商品1行に対する合計金額を計算
    total(data) {
      let price = parseInt(data.total_tax_price, 10);
      price += parseInt(data.send_price, 10);
      price -= parseInt(data.service_price, 10);
      return price;
    },
    changeDateObject(str) {
      return new Date(str);
    }
  },
  methods: {
    ymd(date) {
      const y = date.getFullYear();
      const m = ("0" + (date.getMonth() + 1)).slice(-2);
      const d = ("0" + date.getDate()).slice(-2);

      return y + m + d;
    },
    ymdJp(date) {
      const weekName = ["日", "月", "火", "水", "木", "金", "土"];
      const y = date.getFullYear();
      const m = ("0" + (date.getMonth() + 1)).slice(-2);
      const d = ("0" + date.getDate()).slice(-2);

      let str = y + "年" + m + "月" + d + "日";
      return str + "（" + weekName[date.getDay()] + "）";
    },
    ymdFormat(date) {
      const y = date.getFullYear();
      const m = ("0" + (date.getMonth() + 1)).slice(-2);
      const d = ("0" + date.getDate()).slice(-2);

      return y + "/" + m + "/" + d;
    },
    mdFormat(date) {
      const m = date.getMonth() + 1;
      const d = date.getDate();

      return m + "/" + d;
    },
    calcableDate(date) {
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();

      const day = new Date(y, m - 1, d);
      return day;
    }
  }
};
</script>
