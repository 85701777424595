<template>

    <header id="header">
      <div class="header-inner">
        <router-link to="/iseya/home/" class="logo"
          ><img alt="" src="../../assets/logo@2x.png"
        /></router-link>
        <div class="header-name">有限会社 イセヤ</div>
      </div>
      <fixedMenu />
    </header>


</template>

<script>
import fixedMenu from "@/components/iseya/fixedMenu";
export default {
  name: "iseyaHeader",
  components: {
    fixedMenu
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#header {
  width: 100%;
  height: 40px;
  background: #fff;
  display: flex;
  justify-content: center;
}
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 0 10px;
}
.logo {
  width: 110px;
}
.header-name {
  position: relative;
  width: 160px;
  height: 100%;
  font-size: 1.6rem;
  transform: scale(0.75);
  color: variables.$txt-default;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
@media print {
  /*
  @page portrait {
    size: A4 portrait;
  }
  @page landscape {
    size: A4 landscape;
  }

  html,
  body {
    &.print-portrait {
      page: portrait;
    }
    &.print-landscape {
      page: landscape;
    }
  }
  */
  html,
  body {
    padding: 0;
    margin: 0;
    height: auto;
  }
  #overlay {
    padding: 0 !important;
  }

  #wrapper,
  #fixedMenu,
  #pageTitle-area,
  #header,
  #meta,
  .meta-blk {
    display: none !important;
  }
  #overlay {
    position: relative !important;
  }
}
.meta-calendar__input {
  color: rgba(255, 255, 255, 0);
}

.print-paper {
  background: #fff;
  box-sizing: border-box;
  position: relative;
  //border: solid 2px #f00;
  .meta-blk {
    display: flex !important;
  }
  .print-inner {
    max-width: 100%;
    max-height: 100%;
  }
  .list {
    transform: scale(0.825);
    transform-origin: top left;
    margin-bottom: 0 !important;
    &.no-shrink {
      transform: scale(1);
    }
  }

  @media screen {
    padding: 8mm;
    margin: 0 auto 30px;
  }
  @media print {
    -webkit-print-color-adjust: exact;
    page-break-before: always;
    padding: 8mm;
  }
  &.portrait {
    width: 209mm;
    height: 296mm;
    
    @media print {
      width: 210mm;
      height: 296mm;
    }
    @page {
      size: portrait !important;
      margin: 0 !important;
    }
    
  }
  &.landscape {
    //width: 841px;
    //height: 595px;

    width: 297mm;
    height: 210mm;

    @media print {
      //A4の実際のサイズ
      //width: 297mm;
      //height: 210mm;
      width: 296mm;
      height: 209mm; //たぶんピクセルに直した時に小数点が入ってはみ出すので1mm減らしておく
    }
    
    @page {
      size: landscape !important;
      margin: 0 !important;
    }
    
  }
}
</style>
