<template>
  <div id="fixedMenu" :class="{ isVisible: fixedMenu == true }">
    <div class="fixedMenu-header" :class="{ isVisible: fixedMenu == true }">
      <p class="fixedMenu-ttl" :class="{ isVisible: fixedMenu == true }">
        受付状況
      </p>
      <div
        class="fixedMenu-btn"
        :class="{ isVisible: fixedMenu == true }"
        v-on:click="fixedMenuChange"
      ></div>
    </div>
    <div class="fixedMenu-content" :class="{ isVisible: fixedMenu == true }">
      <div
        class="lock fixedMenu-content__item"
        :class="{ locked: todaysLock }"
        v-on:click="todaysLockChange"
      >
        <span v-if="todaysLock" class="lock-item">
          <span class="icon">
            <img alt="" src="../../assets/icon/lock-locked.svg" />
          </span>
          本日締切分
          <span class="emphasize">ロック中</span>
        </span>
        <span v-if="!todaysLock" class="lock-item">
          <span class="icon">
            <img alt="" src="../../assets/icon/lock-unlock.svg" />
          </span>
          本日締切分
          <span class="emphasize">受付中</span>
        </span>
      </div>

      <div
        class="deadLineLabel fixedMenu-content__item"
        :class="{ visible: deadLineLabel == true }"
        v-on:click="deadLineLabelChange"
      >
        <span v-if="deadLineLabel == true" class="lock-item">
          締切ラベル
          <span class="emphasize">表示</span>
        </span>
        <span v-if="deadLineLabel == false" class="lock-item">
          締切ラベル
          <span class="emphasize">非表示</span>
        </span>
      </div>
    </div>

    <div id="overlay" :class="{ visible: overlay }">
      <div id="overlay-window">
        <div class="dialog">
          <p class="dialog-ttl">本日締切分 詳細</p>
          <p class="dialog-body">調整中</p>
          <!--<p class="dialog-body" v-for="(item, k) in items" :key="k">
            発送分{{ item.name }}
          </p>-->
        </div>
        <div class="overlay-btn">
          <div v-on:click="overlay = !overlay">
            <btnS btnTxt="閉じる" color="back" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/module/api";
import btnS from "@/components/btn/btnS";
export default {
  name: "fixedMenu",
  components: {
    btnS
  },
  data: function() {
    return {
      overlay: false,
      items: [],
      todaysLockStatus: false
    };
  },
  async created() {
    const api = new ApiClient();
    const result = await api.get("/iseya/rock/check");
    console.log(result);
    if (result.check === 0) {
      this.$store.dispatch("setTodaysLock", false);
    } else {
      this.$store.dispatch("setTodaysLock", true);
    }

    const item = await api.get("/iseya/item/list/s");
    this.items = item.list;
    //this.todaysLockStatus = this.$store.state.todaysLock;
  },
  computed: {
    fixedMenu: function() {
      return this.$store.state.fixedMenu;
    },
    todaysLock: function() {
      return this.$store.state.todaysLock;
    },
    deadLineLabel: function() {
      return this.$store.state.deadLineLabel;
    }
  },
  methods: {
    fixedMenuChange() {
      this.$store.state.fixedMenu = !this.$store.state.fixedMenu;
    },
    async todaysLockChange() {
      const api = new ApiClient();
      await api.get("/iseya/rock/save");
      this.$store.dispatch("setTodaysLock", true);
      /*
      if (!this.$store.state.todaysLock) {
        this.$store.dispatch("setTodaysLock", true);
      } else {
        this.$store.dispatch("setTodaysLock", false);
      }
      */
      this.todaysLockStatus = this.$store.state.todaysLock;
      //this.todaysLockStatus = true;
      //console.log(this.todaysLockStatus);
    },
    deadLineLabelChange() {
      this.$store.state.deadLineLabel = !this.$store.state.deadLineLabel;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#fixedMenu {
  position: fixed;
  right: 15px;
  bottom: 15px;
  width: 36px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease-out;
  border-radius: 50%;
  z-index: 3;
  &.isVisible {
    width: 180px;
    border-radius: 0;
  }
}

.fixedMenu-header {
  height: 36px;
  background: variables.$txt-default;
  color: #fff;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 50%;

  &.isVisible {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.fixedMenu-ttl {
  font-size: 12px;
  font-weight: 700; /* bold */
  letter-spacing: 0.05em;
  line-height: 1;
  transition: all 0.3s ease-out;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  &.isVisible {
    width: 100%;
    opacity: 1;
    transition: all 0.3s ease-out 0.4s;
  }
}

.fixedMenu-content {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.2s ease-out 0.2s;
  transform: translate(0, -100px);
  opacity: 0;
  height: 0;
  background: #fff;
  &.isVisible {
    width: 100%;
    height: 105px;
    padding: 15px;
    transform: translate(0, 0);
    opacity: 1;
    transition: all 0.2s ease-out 0.2s;
    .fixedMenu-content__item {
      opacity: 1;
      transition: all 0.2s ease-out 0.5s;
    }
  }
}

.fixedMenu-content__item {
  opacity: 0;
  transition: all 0.2s ease-out;
}

.fixedMenu-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  width: 36px;
  height: 36px;
  transition: all 0.2s ease-out;
  &:before,
  &:after {
    content: "";
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1px;
    height: 12px;
    transition: all 0.2s ease-out;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  &.isVisible {
    border-top-right-radius: 3px;
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.emphasize {
  font-size: 12px;
  font-weight: 700; /* bold */
}

.lock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  position: relative;

  width: 150px;
  height: 30px;
  padding-left: 15px;
  box-sizing: border-box;

  font-size: 10px;
  letter-spacing: 0.1em;
  color: variables.$secondary;
  border: solid 1px variables.$secondary;

  margin-bottom: 15px;
  cursor: pointer;
  &.locked {
    color: #fff;
    background: variables.$scrollbar-back;
    border-color: variables.$scrollbar-back;
    pointer-events: none;
  }

  .icon {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.lock-item {
  opacity: 0;
  animation: fade 0.4s ease forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.deadLine {
  letter-spacing: 0.1em;
  text-decoration: underline;
  margin-bottom: 15px;
  display: inline-block;
  cursor: pointer;
}

.deadLineLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  position: relative;

  width: 150px;
  height: 30px;
  box-sizing: border-box;

  font-size: 10px;
  letter-spacing: 0.1em;
  color: variables.$txt-default;
  border: solid 1px variables.$txt-default;

  margin-bottom: 15px;
  cursor: pointer;
  &.visible {
    color: #fff;
    background: variables.$txt-default;
  }
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

#overlay-window {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-body {
  @extend .f_body;
  margin-bottom: 15px;
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
}
</style>
